<template>
  <ValidationObserver v-slot="{ handleSubmit, errors }">
    <form @submit.prevent="handleSubmit(submit)">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">General</h4>
        </md-card-header>

        <md-card-content>
          <div class="md-layout">
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <ValidationProvider
                name="tarjeta_bip"
                :rules="{
                  required: true,
                  numeric: true,
                }"
                v-slot="{ passed, failed }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>TARJETA BIP</label>
                  <md-input
                    type="number"
                    autocomplete="off"
                    v-model="tarjeta_bip"
                    @keydown.space.prevent
                    :disabled="submited"
                  >
                  </md-input>

                  <slide-y-down-transition>
                    <md-icon class="error" v-show="failed">close</md-icon>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon class="success" v-show="passed">done</md-icon>
                  </slide-y-down-transition>
                </md-field>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <ValidationProvider
                name="placa_patente"
                :rules="{
                  required: true,
                  oneOf: $options.validPpu,
                  noica: [servicio, p_mediciones_ica],
                }"
                v-slot="{ passed, failed }"
              >
                <md-field
                  :class="[
                    { 'md-error': failed && !submited },
                    { 'md-valid': passed || submited },
                  ]"
                >
                  <label>PLACA PATENTE</label>
                  <md-input
                    v-model="placa_patente"
                    type="text"
                    autocomplete="off"
                    :disabled="submited"
                  >
                  </md-input>

                  <slide-y-down-transition>
                    <md-icon class="error" v-show="failed && !submited"
                      >close</md-icon
                    >
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon class="success" v-show="passed || submited"
                      >done</md-icon
                    >
                  </slide-y-down-transition>
                </md-field>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <ValidationProvider
                name="unidad_negocio"
                rules="required"
                v-slot="{ passed, failed }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>UNIDAD NEGOCIO</label>
                  <md-input
                    type="text"
                    id="unidad_negocio"
                    v-bind:value="ppuUn"
                    disabled
                  >
                  </md-input>

                  <slide-y-down-transition>
                    <md-icon class="error" v-show="failed">close</md-icon>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon class="success" v-show="passed">done</md-icon>
                  </slide-y-down-transition>
                </md-field>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <ValidationProvider
                name="estandar"
                rules=""
                v-slot="{ passed, failed }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>ESTANDAR</label>
                  <md-input
                    id="estandar"
                    type="text"
                    v-bind:value="ppuEs"
                    disabled
                  >
                  </md-input>

                  <slide-y-down-transition>
                    <md-icon class="error" v-show="failed">close</md-icon>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon class="success" v-show="passed">done</md-icon>
                  </slide-y-down-transition>
                </md-field>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <ValidationProvider
                name="servicio"
                rules="required"
                v-slot="{ passed, failed }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label for="servicio">SERVICIO</label>
                  <md-select
                    name="servicio"
                    id="servicio"
                    v-model="servicio"
                    vid="servicio"
                    :disabled="submited"
                  >
                    <md-option value=""></md-option>
                    <md-option
                      v-for="option in unServicio"
                      :key="option.toUpperCase()"
                      :value="option.toUpperCase()"
                      >{{ option.toUpperCase() }}</md-option
                    >
                  </md-select>
                </md-field>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <ValidationProvider
                name="lugar_inicio"
                rules="required"
                v-slot="{ passed, failed }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>LUGAR INICIO</label>
                  <md-input
                    type="text"
                    autocomplete="off"
                    v-model="lugar_inicio"
                    :disabled="submited"
                    @keydown.space.prevent
                  >
                  </md-input>

                  <slide-y-down-transition>
                    <md-icon class="error" v-show="failed">close</md-icon>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon class="success" v-show="passed">done</md-icon>
                  </slide-y-down-transition>
                </md-field>
              </ValidationProvider>
            </div>
          </div>
        </md-card-content>

        <md-card-actions>
          <span class="md-helper-error" v-if="!submited">{{
            Object.values(errors).flat()[0]
          }}</span>
          <md-button type="submit" class="md-success" :disabled="submited"
            >Iniciar
          </md-button>
        </md-card-actions>
      </md-card>
    </form>
  </ValidationObserver>
</template>
<script>
import { ValidationProvider } from "vee-validate";
import { ValidationObserver } from "vee-validate";
import { SlideYDownTransition } from "vue2-transitions";
import { extend } from "vee-validate";
import { required, oneOf, numeric } from "vee-validate/dist/rules";
import buses from "@/assets/json/buses.json";
import servicios from "@/assets/json/servicios.json";
import { customAlphabet } from "nanoid";
import { alphanumeric } from "nanoid-dictionary";
const nanoid = customAlphabet(alphanumeric, 20);
const validPpu = Object.keys(buses);
extend("required", {
  ...required,
  message: "* Campo requerido",
});
extend("oneOf", {
  ...oneOf,
  message: "* Valor invalido",
});
extend("numeric", {
  ...numeric,
  message: "* Valor invalido",
});
extend("noica", {
  message: "* ICA ya registrada",
  validate: (value, [value1, value2]) => {
    if (value && value1 && value2)
      return !value2.includes(value.concat(value1));
    else return true;
  },
});

function mapStateTwoWay(...args) {
  const result = {};
  for (const prop of Object.keys(args[0])) {
    result[prop] = {
      get() {
        return this.$store.getters.getIcaIni({
          param: prop,
        });
      },
      set(value) {
        if (typeof value === "string") value = value.toUpperCase();
        this.$store.commit(args[0][prop], {
          value: value,
          param: prop,
        });
      },
    };
  }
  return result;
}

export default {
  validPpu: validPpu,
  components: {
    SlideYDownTransition,
    ValidationProvider,
    ValidationObserver,
  },
  computed: {
    ppuUn: function() {
      return buses?.[this.placa_patente]?.[0];
    },
    ppuTv: function() {
      return buses?.[this.placa_patente]?.[1];
    },
    unServicio: function() {
      return servicios?.[buses?.[this.placa_patente]?.[0]];
    },
    ppuNp: function() {
      return buses?.[this.placa_patente]?.[2];
    },
    ppuEs: function() {
      return buses?.[this.placa_patente]?.[3];
    },
    p_mediciones_ica: function() {
      return this.$store?.state?.p_mediciones_ica?.ppu_servicio;
    },
    ...mapStateTwoWay({
      placa_patente: "setIcaIni",
      tarjeta_bip: "setIcaIni",
      servicio: "setIcaIni",
      lugar_inicio: "setIcaIni",
    }),
  },
  data() {
    return {
      submited: false,
      saved: false,
    };
  },
  watch: {
    placa_patente: function() {
      this.servicio = null;
    },
  },
  created: function() {
    this.$options.location = { latitude: "", longitude: "" };
    this.$options.watchid = navigator.geolocation.watchPosition(
      (location) => {
        this.$options.location.latitude = location.coords.latitude;
        this.$options.location.longitude = location.coords.longitude;
      },
      () => {},
      { enableHighAccuracy: true, timeout: 5000, maximumAge: 1 * 60 * 1000 }
    );
  },
  beforeDestroy: function() {
    navigator.geolocation.clearWatch(this.$options.watchid);
    if (this.saved) this.$store.commit("clearIcaIni");
  },
  methods: {
    submit() {
      this.submited = true;
      const payload = {
        uuid: nanoid(),
        user_uid: this.$store.state.user.uid,
        user_name: this.$store.state.user.name,
        fecha_inicio: new Date().toLocaleDateString("en-GB", {
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
        }),
        hora_inicio: new Date().toLocaleTimeString([], { hour12: false }),
        fecha_inicio_timestamp: Date.now() / 1000,
        unidad_negocio: parseInt(this.ppuUn),
        tipo_veh: this.ppuTv,
        num_puertas: parseInt(this.ppuNp),
        position_latitude_inicio: this.$options.location.latitude,
        position_longitude_inicio: this.$options.location.longitude,
        tarjeta_bip: parseInt(this.tarjeta_bip),
        estado: 0,
        placa_patente: this.placa_patente,
        servicio: this.servicio,
        lugar_inicio: this.lugar_inicio,
        estandar: this.ppuEs,
      };
      this.$store.dispatch("medicaAdd", payload);
      this.$store.dispatch("medicaParam", payload);
      this.$store.commit("initIcaDet", {
        uuid: payload.uuid,
        fecha_inicio_timestamp: payload.fecha_inicio_timestamp,
        placa_patente: payload.placa_patente,
        servicio: payload.servicio,
        estandar: payload.estandar,
        unidad_negocio: payload.unidad_negocio,
        tipo_veh: payload.tipo_veh,
      });
      this.$notify({
        timeout: 1500,
        message: "Medición ICA Iniciada",
        icon: "add_alert",
        verticalAlign: "top",
        horizontalAlign: "center",
        type: "success",
      });
      this.saved = true;
      setTimeout(
        () =>
          this.$router.push({
            name: "medica_det",
            params: { uuid: payload.uuid },
          }),
        2000
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.md-card .md-card-actions {
  border: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.md-helper-error {
  color: #f44336;
  font-size: 0.75rem;
  padding-right: 30px;
}
</style>
